
import { defineComponent, ref } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import EmployeeModal from '@/components/modals/forms/employee/EmployeeModal.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'stipend-disbursement-report',
  components: {
    Datatable,
    EmployeeModal,
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    return {
      batch: {
        tranche: '' as any,
        entity_id: '' as any,
        start_date: '',
        end_date: '',
      },
      moment: '' as any,
      showCourseNotice: false,
      showSummary: false,
      reportLoading: false,
      summaryLoading: false,
      quarterlyLoading: false,
      trainingPartnerTableHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'Bank Account & 80% Attendance',
          key: 'bank_account_attendence',
          sortable: false,
        },
        {
          name: 'Ethnic (A)',
          key: 'ethnic',
          sortable: false,
        },
        {
          name: 'Disabled(B)',
          key: 'disabled',
          sortable: false,
        },
        {
          name: 'Female of Poor District (C)',
          key: 'female_poor_district',
          sortable: false,
        },
        {
          name: 'Poor (D)',
          key: 'poor',
          sortable: false,
        },
        {
          name: 'Total (A+B+C+D)',
          key: 'total',
          sortable: false,
        },
        {
          name: 'Action',
          key: 'action',
          sortable: false,
        },
      ],
      lists: [],
      search: '',
      alltableData: [],
      quarterlyData: [],
      entity_type: '',
      data: {},
      entityInfos: [],
      tabIndex: ref(0),
      tabnextIndex: ref(1),
      tranches_info: [],
      entityReportData: [] as any,
      mobilebankReportData: [] as any,
      bankReportData: [] as any,
      agentbankReportData: [] as any,
      summary: [],
      total_bank_account_entity: 0 as any,
      total_ethnic_entity: 0 as any,
      total_disable_entity: 0 as any,
      total_female_entity: 0 as any,
      total_poor_entity: 0 as any,
      total_poor_bank: 0 as any,
      total_poor_mobile: 0 as any,
      total_poor_agent: 0 as any,
      total_female_bank: 0 as any,
      total_female_mobile: 0 as any,
      total_female_agent: 0 as any,
      total_disable_bank: 0 as any,
      total_disable_agent: 0 as any,
      total_disable_mobile: 0 as any,
      total_ethnic_bank: 0 as any,
      total_ethnic_mobile: 0 as any,
      total_ethnic_agent: 0 as any,
      total_bank_account_bank: 0 as any,
      total_bank_account_mobile: 0 as any,
      total_bank_account_agent: 0 as any,
    };
  },
  async created() {
    this.moment = moment;
    this.entity_type = VueCookieNext.getCookie('_seip_entity_type');
    await this.getTranches();
    await this.getEntityInfos();
    await this.getStipendSummary();
    await this.getStipendQuarterlySummary();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getStipendSummary() {
      this.summaryLoading = true;
      await ApiService.get('stipend/management-report/disbursement-report')
        .then((response) => {
          this.alltableData = response.data.data;
          this.showSummary = true;
          this.summaryLoading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.summaryLoading = false;
        });
    },
    async getStipendQuarterlySummary() {
      this.quarterlyLoading = true;
      await ApiService.get('stipend/management-report/disbursement-quarterly')
        .then((response) => {
          this.quarterlyData = response.data.data;
          this.quarterlyLoading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.quarterlyLoading = false;
        });
    },

    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityInfos() {
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          console.log(response);
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getData() {
      console.log(this.batch.entity_id.length);
      if (this.batch.tranche != false && this.batch.entity_id != false) {
        this.reportLoading = true;
        await ApiService.get(
          'stipend/management-report/entity-disbursement-report?tranche=' +
            this.batch.tranche +
            '&entity=' +
            this.batch.entity_id +
            '&start_date=' +
            this.batch.start_date +
            '&end_date=' +
            this.batch.end_date
        )
          .then((response) => {
            this.reportLoading = false;
            this.entityReportData = response.data.data.entity;
            this.bankReportData = response.data.data.bank;
            this.mobilebankReportData = response.data.data.mobile_bank;
            this.agentbankReportData = response.data.data.agent_bank;

            // sun all entity
            const sum = this.entityReportData.reduce(
              (acc, cur) => {
                acc.bank += cur.bank;
                acc.ethnic += cur.ethnic;
                acc.disabled += cur.disabled;
                acc.female += cur.female;
                acc.poor += cur.poor;

                return acc;
              },
              {
                bank: 0,
                ethnic: 0,
                disabled: 0,
                female: 0,
                poor: 0,
              }
            );
            this.total_bank_account_entity = sum?.bank;
            this.total_ethnic_entity = sum?.ethnic;
            this.total_disable_entity = sum?.disabled;
            this.total_female_entity = sum?.female;
            this.total_poor_entity = sum?.poor;

            // bank sum
            const bank = this.bankReportData.reduce(
              (acc, cur) => {
                acc.bank += cur.bank;
                acc.ethnic += cur.ethnic;
                acc.disabled += cur.disabled;
                acc.female += cur.female;
                acc.poor += cur.poor;

                return acc;
              },
              {
                bank: 0,
                ethnic: 0,
                disabled: 0,
                female: 0,
                poor: 0,
              }
            );
            this.total_bank_account_bank = bank?.bank;
            this.total_ethnic_bank = bank?.ethnic;
            this.total_disable_bank = bank?.disabled;
            this.total_female_bank = bank?.female;
            this.total_poor_bank = bank?.poor;
            // mobile sum
            const mobile = this.mobilebankReportData.reduce(
              (acc, cur) => {
                acc.bank += cur.bank;
                acc.ethnic += cur.ethnic;
                acc.disabled += cur.disabled;
                acc.female += cur.female;
                acc.poor += cur.poor;

                return acc;
              },
              {
                bank: 0,
                ethnic: 0,
                disabled: 0,
                female: 0,
                poor: 0,
              }
            );
            this.total_bank_account_mobile = mobile?.bank;
            this.total_ethnic_mobile = mobile?.ethnic;
            this.total_disable_mobile = mobile?.disabled;
            this.total_female_mobile = mobile?.female;
            this.total_poor_mobile = mobile?.poor;
            // agent sum
            const agent = this.agentbankReportData.reduce(
              (acc, cur) => {
                acc.bank += cur.bank;
                acc.ethnic += cur.ethnic;
                acc.disabled += cur.disabled;
                acc.female += cur.female;
                acc.poor += cur.poor;

                return acc;
              },
              {
                bank: 0,
                ethnic: 0,
                disabled: 0,
                female: 0,
                poor: 0,
              }
            );
            this.total_bank_account_agent = agent?.bank;
            this.total_ethnic_agent = agent?.ethnic;
            this.total_disable_agent = agent?.disabled;
            this.total_female_agent = agent?.female;
            this.total_poor_agent = agent?.poor;

            this.showSummary = true;
          })
          .catch(({ response }) => {
            console.log(response);
            this.reportLoading = false;
          });
        this.showCourseNotice = true;
      } else {
        Swal.fire({
          title: 'Warning',
          text: 'Please Select Tranche & Training Partner!',
          icon: 'warning',
        });
      }

      // console.log(this.batch);
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
    setActivenextTab(event) {
      this.tabnextIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
    async getEntity() {
      await ApiService.get('entity/infos/' + this.entityInfoID)
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
